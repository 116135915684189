import { createRef, PureComponent } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import { TweenMax, Power0 } from 'gsap/all';
import initSnow from './helpers/snow';
import YouTube from 'react-youtube';
import {Howl} from 'howler';

import people from './data/people.json';
import './styles/_index.scss';
import classes from './App.module.scss';

const FOOTER_HEIGHT = 80; // px

class App extends PureComponent {
  state = {
    openedHuman: null,
    scrolling: false,
    shift: 0,
    startScreen: true,
    startScreenNext: false
  };

  music = null;
  animation = null;
  listRef = createRef();

  componentDidMount() {
    setTimeout(() => {
      this.startScroll();
    }, 1000);

    this.init();
  }

  componentWillUnmount() {
    if (this.music && typeof this.music.unload === 'function') {
      this.music.unload();
    }
  }

  init() {
    initSnow();

    this.music = new Howl({
      src: '/music/Alexey_Anisimov_-_Magical_Christmas.mp3',
      autoplay: false,
      loop: true
    });

    setTimeout(() => {
      this.setState({
        startScreenNext: true
      });
    }, 3000);
  }

  runMusic() {
    this.music.play();
  }

  onScrollComplete = () => {
    this.setState({
      shift: this.state.shift + 1,
    }, () => {
      TweenMax.set(this.listRef.current, {
        x: 0,
      });

      this.scroll();
    });
  };

  scroll = () => {
    this.animation = TweenMax.to(this.listRef.current, 5, {
      x: -((window.innerHeight - FOOTER_HEIGHT) / 4),
      ease: Power0.easeNone,
      onComplete: this.onScrollComplete
    });
  };

  startScroll() {
    this.setState({
      scrolling: true
    }, () => {
      if (this.animation) {
        this.animation.play();
      } else {
        this.scroll();
      }
    });
  }

  stopScroll() {
    this.setState({
      scrolling: false
    }, () => {
      if (this.animation) {
        this.animation.pause();
      }
    });
  }

  getPeople() {
    const {shift} = this.state;

    const needHumansCount = (window.innerWidth / ((window.innerHeight - FOOTER_HEIGHT) / 4) + 1) * 4 - 1 + (shift * 4);

    const resultPeople = [{
      name: 'Effective',
      image: 'T71P8C1C6-UGEQHBCKA-42f663cc11df-512.png'
    }];

    while (resultPeople.length < needHumansCount) {
      people.forEach(human => {
        resultPeople.push(human);
      });
    }

    return resultPeople.slice(shift * 4);
  }

  showHuman = (human) => {
    if (!human.text && !human.youtubeId) {
      return;
    }

    this.music.fade(1, 0.1, 500);
    this.stopScroll();

    this.setState({
      openedHuman: human
    });
  };

  closeHuman = () => {
    this.setState({
      openedHuman: null
    }, () => {
      this.music.fade(1.5, 1, 1500);
      this.startScroll();
    });
  };

  closeStartScreen = () => {
    this.runMusic();

    this.setState({
      startScreen: false
    });
  };

  render() {
    const {openedHuman, startScreen, startScreenNext, shift} = this.state;

    return (
      <>
        <div className={classes.Container}>
          <ul
            ref={this.listRef}
            className={classes.Grid}
          >
            {this.getPeople().map((human, key) => (
              <li
                key={`${human.name}${key + (shift * 4)}`}
                className={cx(
                  classes.GridItem,
                  {
                    'icon-file-video': human.youtubeId,
                    'icon-file-text': human.text
                  }
                )}
                onClick={() => this.showHuman(human)}
              >
                <img
                  title={human.name}
                  src={`/avatars/${human.image}`}
                  alt={human.name}
                  className={classes.GridImage}
                />
                {
                  human.name ?
                    <p className={classes.GridName}>
                      {human.name}
                    </p>
                    : null
                }
              </li>
            ))}
          </ul>
          <div className={classes.Footer}>
            <a
              href='https://effective.band'
              className={classes.FooterLogo}
            >
              <img
                src='/logo.svg'
                alt='Effective'
                className={classes.FooterLogoImage}
              />
            </a>
            <div className={classes.FooterCopy}>
              2021 Year in Review
            </div>
          </div>
        </div>
        {
          <div
            className={cx(
              classes.StartScreen,
              {
                [classes.StartScreenStarted]: !startScreen,
                [classes.StartScreenNext]: startScreenNext
              }
            )}
            onClick={this.closeStartScreen}
          >
            <img
              src='/logo_mini.svg'
              alt='Effective'
              className={classes.StartScreenLogo}
            />
            <div className={classes.StartScreenCopy}>
              2021 Year in Review
            </div>
            <div className={classes.StartScreenHint}>
              Touch anywhere to start
            </div>
          </div>
        }
        {
          openedHuman ?
            <Modal
              isOpen={true}
              ariaHideApp={false}
              contentLabel={openedHuman.name}
              className={classes.Modal}
              overlayClassName={classes.ModalOverlay}
              portalClassName={classes.ModalPortal}
              onRequestClose={this.closeHuman}
            >
              <div className={classes.ModalColumn}>
                <img
                  src={`/avatars/${openedHuman.image}`}
                  alt={openedHuman.name}
                  className={classes.ModalImage}
                />
                <h2 className={classes.ModalName}>
                  {openedHuman.name}
                </h2>
                {
                  openedHuman.company ?
                    <p className={classes.ModalCompany}>
                      ({openedHuman.company})
                    </p>
                    : null
                }
              </div>
              <div className={classes.ModalColumn}>
                {
                  openedHuman.text ?
                    <div className={classes.ModalInfo}>
                      {
                        openedHuman.text.map((p, key) => (
                          <p
                            key={key}
                            className={classes.ModalInfoP}
                          >
                            {p}
                          </p>
                        ))
                      }
                    </div>
                    : null
                }
                {
                  openedHuman.youtubeId ?
                    <YouTube
                      videoId={openedHuman.youtubeId}
                      containerClassName={classes.ModalYoutube}
                      className={classes.ModalYoutubeVideo}
                      opts={{
                        playerVars: {
                          controls: 0,
                          autoplay: 1,
                          showinfo: 0,
                          rel: 0,
                          modestbranding: 1,
                          autohide: 1
                        }
                      }}
                    />
                    : null
                }
                <i
                  className={classes.ModalCloseBtn}
                  onClick={this.closeHuman}
                />
              </div>
            </Modal>
            : null
        }

      </>
    );
  };
}

export default App;
